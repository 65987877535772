import MainHeading from 'components/atoms/MainHeading'
import Seo from 'components/atoms/Seo'
import { graphql, Link, useStaticQuery } from 'gatsby'

const NotFoundPage = () => {
  const { datoCmsHeader, datoCmsSite, site } = useStaticQuery(query)
  const {
    siteName,
    fallbackSeo: { description },
  } = datoCmsSite.globalSeo
  const { title, subtitle } = datoCmsHeader
  const { siteUrl } = site

  return (
    <>
      <Seo
        title={`404 Page Not Found - ${siteName}`}
        description={description}
        siteUrl={siteUrl}
        lang="en"
      />

      <header className="container mx-auto text-center px-5 py-10">
        <MainHeading title={title} subtitle={subtitle} />
      </header>

      <main>
        <div className="flex justify-center items-center w-full">
          <div className="w-2 h-8 bg-black mx-1"></div>
          <div className="w-2 h-8 bg-black ml-1 mr-2"></div>
          <div className="w-2 h-8 bg-black ml-2 mr-1"></div>
          <div className="w-2 h-8 bg-black mx-1"></div>
          <div className="w-2 h-8 bg-black mx-1"></div>
        </div>
      </main>

      <footer className="text-center py-10 mt-5">
        <p>
          Pagina non trovata. Torna al{' '}
          <Link className="underline text-teal" to="/">
            sito.
          </Link>
        </p>
      </footer>
    </>
  )
}

export const query = graphql`
  query DatoCMSHeader {
    # DatoCMS Seo
    datoCmsSite(locale: { eq: "en" }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
        }
      }
    }

    # Gatsby Config Seo
    site {
      siteMetadata {
        siteUrl
      }
    }

    # Header
    datoCmsHeader(locale: { eq: "en" }) {
      title
      subtitle
      nav {
        id
        text
      }
    }
  }
`

export default NotFoundPage
